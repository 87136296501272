<!-- START OF HEADER COMPONENT -->
<!-- Header Navigation -->
<header class="site-header">
    <!-- mobile toggle button -->
    <button id="toggle-btn" aria-label="toggle" class="navbar-toggler" (click)="openSideNav()">
        <i class="fas fa-bars"></i>
    </button>
    <!-- navbar wrap -->
    <div class="wrap">
        <!-- mobile side navigation -->
        <div id="side-nav" class="sidenav" [ngClass]="{'menu-open': sideMenu}">
        <ul class="navbar-nav">
            <!-- home nav link -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/home" routerLinkActive="active" 
                    aria-current="page" itemprop="url" (click)="openSideNav()">Home</a>
            </li>
            <!-- about nav link -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/about" routerLinkActive="active" 
                    itemprop="url" (click)="openSideNav()">About</a>
            </li>
            <!-- services nav link -->
            <li class="nav-item dropdown" data-bs-toggle="collapse" href="#mobileCollapse" 
                role="button" aria-expanded="false" aria-controls="mobileCollapse">
                <!-- dropdown link -->
                <a dropdownToggle class="nav-link" routerLink="/services" routerLinkActive="active">
                    Services <i class="fas fa-angle-down"></i>
                </a>
                <!-- mobile dropdown -->
                <div class="collapse" id="mobileCollapse">
                    <!-- evaluation nav link -->
                    <a class="dropdown-item sub-menu-item" routerLink="/services/evaluation"
                        routerLinkActive="active" (click)="openSideNav()">Evaluation</a>
                    <!-- individual therapy nav link -->
                    <a class="dropdown-item sub-menu-item" routerLink="/services/individual-therapy"
                        routerLinkActive="active" (click)="openSideNav()">Individual Therapy</a>
                    <!-- family therapy nav link -->
                    <a class="dropdown-item sub-menu-item" routerLink="/services/family-therapy"
                        routerLinkActive="active" (click)="openSideNav()">Family Therapy</a>
                    <!-- parent consult nav link -->
                    <a class="dropdown-item sub-menu-item" routerLink="/services/parent-consultation"
                        routerLinkActive="active" (click)="openSideNav()">Parent Consultation</a>
                    <!-- school consult nav link -->
                    <a class="dropdown-item sub-menu-item" routerLink="/services/school-consultation"
                        routerLinkActive="active" (click)="openSideNav()">School Consultation</a>
                    <!-- supervision & training nav link -->
                    <a class="dropdown-item sub-menu-item " routerLink="/services/supervision-and-training"
                        routerLinkActive="active" (click)="openSideNav()">Supervision &amp; Training</a>
                </div>
            </li>
            <!-- who we serve nav link -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/who-we-serve" routerLinkActive="active" 
                    itemprop="url" (click)="openSideNav()">Who We Serve</a>
            </li>
            <!-- resources nav link -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/resources" routerLinkActive="active"
                    itemprop="url" (click)="openSideNav()">Resources</a>
            </li>
            <!-- contact nav link -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/contact" routerLinkActive="active" 
                    itemprop="url" (click)="openSideNav()">Contact</a>
            </li>
            <!-- patient portal link -->
            <li class="nav-item">
                <a class="nav-link button" id="mobile-portal" href="https://drcherylholland.clientsecure.me/" 
                    itemprop="url" target="_blank" (click)="openSideNav()" rel="noopener">Client Portal</a>
            </li>
        </ul>
        </div>
        <!-- desktop top navigation -->
        <nav class="navbar navbar-expand-lg navbar-default navbar-light">
            <!-- navbar wrapper -->
            <div class="container-fluid">
                <!-- navbar brand -->
                <a class="navbar-brand" routerLink="/home" routerLinkActive="active">
                    <img src="../../assets/images/header-logo.webp" alt="practice logo" loading="lazy"/>
                </a>
                <!-- collapsible nav menu -->
                <div class="collapse navbar-collapse w-100 justify-content-end" id="navbar-content">
                    <ul class="navbar-nav">
                        <!-- home nav link -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/home" routerLinkActive="active"
                                aria-current="page" itemprop="url">Home</a>
                        </li>
                        <!-- about nav link -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/about" routerLinkActive="active" 
                                itemprop="url">About</a>
                        </li>
                        <!-- services nav link -->
                        <li class="nav-item dropdown" dropdown>
                            <!-- dropdown link -->
                            <a dropdownToggle id="dropdown" class="nav-link dropdownToggle" routerLink="/services"
                                routerLinkActive="active">Services</a>
                            <!-- desktop dropdown -->
                            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                                <!-- evaluation nav link -->
                                <a class="dropdown-item sub-menu-item" routerLink="/services/evaluation"
                                    routerLinkActive="active">Evaluation</a>
                                <!-- individual therapy nav link -->
                                <a class="dropdown-item sub-menu-item" routerLink="/services/individual-therapy"
                                    routerLinkActive="active">Individual Therapy</a>
                                <!-- family therapy nav link -->
                                <a class="dropdown-item sub-menu-item" routerLink="/services/family-therapy"
                                    routerLinkActive="active">Family Therapy</a>
                                <!-- parent consultation nav link -->
                                <a class="dropdown-item sub-menu-item" routerLink="/services/parent-consultation"
                                    routerLinkActive="active">Parent Consultation</a>
                                <!-- school consultation nav link -->
                                <a class="dropdown-item sub-menu-item" routerLink="/services/school-consultation"
                                    routerLinkActive="active">School Consultation</a>
                                <!-- supervision & training nav link -->
                                <a class="dropdown-item sub-menu-item " routerLink="/services/supervision-and-training"
                                    routerLinkActive="active">Supervision &amp; Training</a>
                            </div>
                        </li>
                        <!-- who we serve nav link -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/who-we-serve" routerLinkActive="active" 
                                itemprop="url">Who We<br>Serve</a>
                        </li>
                        <!-- resources nav link -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/resources" routerLinkActive="active"
                                itemprop="url">Resources</a>
                        </li>
                        <!-- contact nav link -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/contact" routerLinkActive="active" 
                                itemprop="url">Contact</a>
                        </li>
                        <!-- patient portal link -->
                        <li class="nav-item">
                            <a class="nav-link button" id="portal" href="https://drcherylholland.clientsecure.me/" 
                                itemprop="url" target="_blank" rel="noopener">Client Portal</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>
<!-- END OF HEADER COMPONENT -->