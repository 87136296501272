<!-- START OF EVALUATION CONSULT COMPONENT -->
<!-- section header -->
<header class="page-header">
    <h1 class="page-title" itemprop="headline">
        Evaluation
    </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-evaluation.webp"
    class="service-img" loading="lazy"
    alt="mother hugging daughter in field">
<!-- section content -->
<p>When individuals and families are distressed, confused, or struggling with emotional and
    behavioral challenges, evaluations help to sort out priorities and give clarity for positive
    change. We provide evaluation services to comprehensively identify strengths and needs,
    provide diagnostic clarification, and inform treatment recommendations and planning.
    Evaluations usually occur over several sessions.</p>
<p>For children, we typically meet initially with parent(s) only for a clinical interview and
    provide structured behavioral checklists. If appropriate and requested, we conduct school
    observations and consult with other providers working with the child. We then conduct a
    family diagnostic play session followed by an individual child session. The evaluation
    concludes with a parent feedback session providing impressions, immediate recommendations,
    and treatment planning if indicated.</p>
<p>We do not provide psychological or psycho-educational testing as part of the practice. If
    testing is needed, referrals to appropriate providers can be provided.</p>
<!-- END OF EVALUATION CONSULT COMPONENT -->