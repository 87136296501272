import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // google maps
  mapsUrl: SafeResourceUrl;
  linkUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    // validate google maps panel
    this.mapsUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ7ZXSbsHOt4kRwxP-JAO5NyA&key=AIzaSyDmkqUHtZiIqtLOjQWjePiOBpElmHwf2sA'
    );
    // validate google maps link
    this.linkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.google.com/maps/place/10605+Concord+St+%23100,+Kensington,+MD+20895/@39.0302401,-77.0768249,17z/data=!3m1!4b1!4m2!3m1!1s0x89b7cec16ed295ed:0x7ef70cd6127b102b?shorturl=1'
    );
  }

  ngOnInit(): void { }

}
