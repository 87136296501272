<!-- START OF CONTACT COMPONENT -->
<!-- page wrapper -->
<main class="site-inner">
    <!-- page content -->
    <div class="page-content">
        <!-- page header -->
        <header class="page-header">
            <h1 class="page-title" itemprop="headline">
                Contact
            </h1>
        </header>
        <!-- contact info -->
        <p class="appointments">
            For questions about the practice or to request an appointment, please contact the Practice Director, <a href="mailto:chollandphd@gmail.com">Dr. Cheryl Holland.</a>
        </p>
        <div class="row">
            <!-- cheryl contact info -->
            <div class="contact-card">
                Cheryl Holland, Ph.D.<br>
                <a href="tel:%28301%29%20962-0800%2C%20ext.%203" target="_blank" 
                    rel="noopener">(301) 962-0800</a> ext. 1<br>
                <a href="mailto:drcheryl@cherylhollandphd.com">
                    <span>drcheryl@cherylhollandphd.com</span>
                </a><br>
            </div>
            <!-- gwen contact info -->
            <div class="contact-card">
                Gwen Campbell, LCPC<br>
                <a href="tel:%28301%29%20962-0800%2C%20ext.%203" target="_blank" 
                    rel="noopener">(301) 962-0800</a> ext. 2<br>
                <a href="mailto:gwen@cherylhollandphd.com ">
                    <span>gwen@cherylhollandphd.com</span>
                </a><br>
            </div>
            <!-- kelly contact info -->
            <div class="contact-card">
                Kelly Rush, LCSW-C<br>
                <a href="tel:%28301%29%20962-0800%2C%20ext.%203" target="_blank" 
                    rel="noopener">(301) 962-0800</a> ext. 3<br>
                <a href="mailto:kelly@cherylhollandphd.com">
                    <span>kelly@cherylhollandphd.com</span>
                </a><br>
            </div>
        </div>
        <!-- office address -->
        <p>
            10605 Concord Street, Suite 102<br>
            Kensington, Maryland 20895
        </p>
        <br>
        <!-- google maps panel -->
        <div class="google-maps">
            <iframe class="map-frame" loading="lazy" [src]="mapsUrl" allowfullscreen></iframe>
        </div>
        <!-- google maps link -->
        <p>
            <a class="button maps-button" [href]="linkUrl" target="_blank" rel="noopener">
                Click for Directions
            </a>
        </p>
        <!-- associates information -->
        <p>We work in a suite shared by seasoned professionals with expertise in occupational therapy,
            speech and language therapy, and special education. These professionals are independent
            providers and are not affiliated with Cheryl Holland, Ph.D. & Associates, LLC.</p>
        <p>TeeKozKids, LLC<br>Occupational Therapy<br>
            <a href="https://www.teekozkids.com/">www.teekozkids.com</a></p>
        <p>Monica Martinez, M.A.<br>Special Education Advocate<br>
            <a href="mailto:mmartinez@martinezadvocacy.com">mmartinez@martinezadvocacy.com</a></p>
        <p>Jean E. Hickey, M.Ed.<br>Educational Therapist<br>
            <a href="mailto:jhickeyllc@gmail.com">jhickeyllc@gmail.com</a><br>240-460-5306</p>
    </div>
    <!-- <hr>
    <-- contact form -->
    <!--<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe3vd9o0aTC4LMOB379XEWdZ5SotXF2nZivLu0JBOUm_QKEKA/viewform?embedded=true" 
        class="form-wrap" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    <br>-->
</main>
<!-- END OF CONTACT COMPONENT -->