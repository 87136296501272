import { Component, OnInit } from '@angular/core';
import data from './resources.json';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  // resource json data
  resourceData: any = data;

  constructor() { }

  ngOnInit(): void { }

  // toggle resource accordion entries
  toggleEntry(event, index) {
    // activate clicked element
    var element = event.target;
    element.classList.toggle("active");
    // toggle backend active status
    if (this.resourceData[index].isActive) {
      this.resourceData[index].isActive = false;
    } else {
      this.resourceData[index].isActive = true;
    }
    // expand or retract panel 
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.marginBottom = 0;
    } else {
      panel.style.marginBottom = "18px";
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

}
