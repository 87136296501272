<!-- START OF FOOTER COMPONENT -->
<!-- site footer -->
<div class="footer-wrap">
    <!-- link to contact page -->
    <a class="button" routerLink="/contact" routerLinkActive="active">
        Send Us an Email
    </a>
</div>
<!-- site byline -->
<footer class="footer-byline">
    <p>Updated Sep 2021 · Site Created by <a href="https://aboutryanholland.com/">Ryan Holland</a>
</footer>
<!-- END OF FOOTER COMPONENT -->