import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { elementScrollIntoView } from "seamless-scroll-polyfill";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild(ModalDirective) modal: ModalDirective;

  constructor() { }

  ngOnInit(): void { }

  // scroll down
  scroll(el: HTMLElement) {
    elementScrollIntoView(<Element>el, { behavior: "smooth", block: "start", inline: "nearest" });
  }

}
