<!-- START OF INDIVIDUAL THERAPY CONSULT COMPONENT -->
<!-- section header -->
<header class="page-header">
    <h1 class="page-title" itemprop="headline">
        Individual Therapy
    </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-individual-therapy.webp"
    class="service-img" loading="lazy"
    alt="girl pouting on bench">
<!-- section content-->
<p>Individuals may experience inner struggles and painful responses
    to difficult relationships or life events. We provide individual therapy for both children and adults in a supportive
    relationship to address feelings, thoughts, needs, and behaviors. We explore challenges together and work to build
    coping skills, healthy relationships with others, and more adaptive ways to handle challenges. The frequency and
    duration of individual therapy varies from short term intervention lasting a few sessions to longer term work
    occurring weekly, every other week, or monthly.</p>
<p>Therapy with children is flexibly geared to their developmental level and unique needs. Strategies for younger
    children may include play therapy, as play is the language of children for expression, learning, and healing. For
    older children, we may utilize cognitive behavioral strategies and skill building. We include a strong parent
    involvement component to facilitate positive adjustment and to help children generalize skills to their natural
    settings at home and school. When children are receiving other services such as speech or occupational therapy, we
    collaborate with other providers to develop an integrated treatment approach.</p>
<!-- END OF INDIVIDUAL THERAPY CONSULT COMPONENT -->