<!-- page wrapper -->
<main class="site-inner">
    <!-- page header -->
    <header class="page-header">
        <h1 class="page-title" itemprop="headline">
            Who We Serve
        </h1>
    </header>
    <!-- page content -->
    <div class="page-content" itemprop="text">
        <p>We work with individuals and families experiencing challenges in a many areas including:</p>
        <!-- bullet points -->
        <ul>
            <li>
                <h3 class="list-header">Anxiety</h3>
                <p>Excessive Fears, Excessive Shyness, Selective Mutism, 
                    Social Anxiety, Extreme Separation Difficulties</p>
            </li>
            <li>
                <h3 class="list-header">Depression &amp; Other Mood Difficulties</h3>
                <p>Extreme Emotional Intensity, Oversensitivity, Irritability</p>
            </li>
            <img id="img-1" loading="lazy" class="alignright"
                src="../../assets/images/loving.webp" 
                alt="mom looking lovingly at daughter">
            <li>
                <h3 class="list-header">Attention/Activity Level</h3>
                <p>Attention Deficit Hyperactivity Disorder, Executive Control 
                    Difficulties</p>
            </li>
            <li>
                <h3 class="list-header">Autism Spectrum Disorders</h3>
                <p>Related Challenges in Communication and Social Engagement; 
                    Impact on Family Relationships and School Adjustment</p>
            </li>
            <li>
                <h3 class="list-header">Oppositional, &amp; Disruptive Behavior</h3>
                <p>Extreme Aggression and Defiance</p>
            </li>
            <li>
                <h3 class="list-header">Difficult Family Relationships</h3>
                <p>Relationship Challenges, Attachment and Bonding, Setting 
                    Limits, Communication Skills</p>
            </li>
            <img id="img-2" loading="lazy" class="alignright" 
                src="../../assets/images/pouting.webp" 
                alt="upset boy pouting in the park">
            <li>
                <h3 class="list-header">Social Difficulties</h3>
                <p>Difficulty Making and Keeping Friendships, Difficulty with 
                    Empathy and Understanding the Perspectives of Others</p>
            </li>
            <li>
                <h3 class="list-header">School Adjustment Problems</h3>
                <p>School Avoidance, Behavioral &amp; Social Problems in the Classroom, 
                    Impact of Learning Challenges on Behavior and Adjustment</p>
            </li>
            <li>
                <h3 class="list-header">Eating Issues</h3>
                <p>Extremely Picky Eating, Behavioral Difficulties During Meals, Power 
                    Struggles Over Food, Irregular Eating Behavior/Schedule</p>
            </li>
            <li>
                <h3 class="list-header">Sleeping Issues</h3>
                <p>Difficulty Mastering Independent Sleep, Extreme Night Fears, 
                    Frequent Night Waking</p>
            </li>
            <li>
                <h3 class="list-header">Difficult Life Events/Transitions</h3>
                <p>Response to Range of Challenging Events in Family Life, 
                    School, and Community</p>
            </li>
        </ul>
    </div>
    <br>
</main>