<!-- START OF SERVICES COMPONENT -->
<!-- page wrapper -->
<main class="site-inner">
    <!-- page content -->
    <div class="page-content" itemprop="text">
        <!-- side nav menu -->
        <div class="services-nav one-fourth first">
            <!-- page header -->
            <header class="page-header">
                <h1 class="page-title" itemprop="headline">
                    Services
                </h1>
            </header>
            <!-- nav buttons -->
            <div class="service-menu">
                <ul class="service-options">
                    <!-- evaluation link -->
                    <li class="menu-item">
                        <a routerLink='evaluation' routerLinkActive="active" itemprop="url">
                            Evaluation
                        </a>
                    </li>
                    <!-- individual therapy link -->
                    <li class="menu-item">
                        <a routerLink='individual-therapy' routerLinkActive="active" itemprop="url">
                            Individual Therapy
                        </a>
                    </li>
                    <!-- family therapy link -->
                    <li class="menu-item">
                        <a routerLink='family-therapy' routerLinkActive="active" itemprop="url">
                            Family Therapy
                        </a>
                    </li>
                    <!-- parent consultation link -->
                    <li class="menu-item">
                        <a routerLink='parent-consultation' routerLinkActive="active" itemprop="url">
                            Parent Consultation
                        </a>
                    </li>
                    <!-- school consultation link -->
                    <li class="menu-item">
                        <a routerLink='school-consultation' routerLinkActive="active" itemprop="url">
                            School Consultation
                        </a>
                    </li>
                    <!-- supervision & training link -->
                    <li class="menu-item">
                        <a routerLink='supervision-and-training' routerLinkActive="active" itemprop="url">
                            Supervision &amp; Training
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- selected service content -->
        <div class="three-fourths">
            <div class="btn-wrapper">
                <!-- mobile dropdown button -->
                <div class="dropdown btn-group" mdbDropdown>
                    <button mdbDropdownToggle type="button" mdbBtn 
                        class="dropdown-toggle button" id="service-toggle">
                        Services
                    </button>
                    <!-- dropdown options -->
                    <div class="dropdown-menu dropdown-primary">
                        <!-- evaluation link -->
                        <a class="dropdown-item service-option" routerLink='evaluation' 
                            routerLinkActive="active" itemprop="url">Evaluation</a>
                        <!-- individual therapy link -->
                        <a class="dropdown-item service-option" routerLink='individual-therapy'
                            routerLinkActive="active" itemprop="url">Individual Therapy</a>
                        <!-- family therapy link -->
                        <a class="dropdown-item service-option" routerLink='family-therapy' 
                            routerLinkActive="active" itemprop="url">Family Therapy</a>
                        <!-- parent consultation link -->
                        <a class="dropdown-item service-option" routerLink='parent-consultation'
                            routerLinkActive="active" itemprop="url">Parent Consultation</a>
                        <!-- school consultation link -->
                        <a class="dropdown-item service-option" routerLink='school-consultation'
                            routerLinkActive="active" itemprop="url">School Consultation</a>
                        <!-- supervision & training link -->
                        <a class="dropdown-item service-option" routerLink='supervision-and-training'
                            routerLinkActive="active" itemprop="url">Supervision &amp; Training</a>
                    </div>
                </div>
            </div>
            <!-- router for selected service -->
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</main>
<!-- END OF SERVICES COMPONENT -->