<!-- START OF PARENT CONSULT COMPONENT -->
<!-- section header -->
<header class="page-header">
    <h1 class="page-title" itemprop="headline">
        Parent Consultation
    </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-parent-consult.webp" class="service-img" loading="lazy"
    alt="dad hugging smiling daughter">
<!-- section content -->
<p>Many parents experience great pleasure and distress in their parenting
    journeys. Parents and families may benefit from the opportunity to discuss their challenges without their child
    present. We provide parent consultation to explore parenting difficulties and stress and to provide parenting
    strategies and support. Consultation often addresses family connections, navigating power struggles, and
    establishing effective limits. For some families, parent consultation also addresses difficult feelings around
    parenting struggles. It often supports parents in developing more accepting, compassionate ways of relating to
    themselves and their children.</p>
<p>Some families seek and benefit from parent consultation that involves only sessions with the parent(s). These
    sessions support behavioral plans at home and provide support while the child is receiving individual therapy with
    another clinician. In other situations, we meet with family members in collaborative parent sessions on a weekly,
    monthly, or less frequent basis to augment ongoing therapy with children provided by the same clinician. Your
    clinician will work with you to determine the best fit for your family for parent consultation services.</p>
<!-- END OF PARENT CONSULT COMPONENT -->