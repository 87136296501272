import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // mobile side menu status
  public sideMenu = false;
  scrWidth: any;

  constructor(private router: Router) {}

  ngOnInit() { }

  // track screen size
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.scrWidth = window.innerWidth;
  }

  // open side navigation
  openSideNav() {
    // update screen size
    this.getScreenSize();
    // open or close menu
    if(this.sideMenu) {
      document.getElementById("side-nav").style.width = "0";
    } else if(this.scrWidth <= 768) {
      document.getElementById("side-nav").style.width = "60vw";
    } else {
      document.getElementById("side-nav").style.width = "35vw";
    }
    // update menu status
    this.sideMenu = !this.sideMenu;
  }

}
