<!-- START OF SCHOOL CONSULT COMPONENT -->
<!-- section header -->
<header class="page-header">
    <h1 class="page-title" itemprop="headline">
        School Consultation
    </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-school-consult.webp"
    class="service-img" loading="lazy"
    alt="children running in field">
<!-- section content -->
<p>Children’s social-emotional and behavioral challenges sometimes negatively impact school adjustment and academic
    success. School settings and demands may also create significant stress for some children. Teachers may also seek
    input to understand children’s needs and search for classroom strategies to support and accommodate challenges.</p>
<p>If indicated and requested, we provide school consultation to support children’s healthy adjustment and learning in
    the school environment. Consultations may include school observation(s), recommendations for supporting
    social-emotional and behavioral development in the classroom, and/or ongoing consultation including regular meetings
    with school staff.</p>
<!-- END OF SCHOOL CONSULT COMPONENT -->