<!-- START OF FAMILY THERAPY CONSULT COMPONENT -->
<!-- section header -->
<header class="page-header">
        <h1 class="page-title" itemprop="headline">
            Family Therapy
        </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-family-therapy.webp"
    class="service-img" loading="lazy"
    alt="mother and son in field">
<!-- section content -->
<p>Family relationships are central to well-being and adjustment. Yet, families often struggle with roles,
    communication, and acceptance of inevitable differences in needs. In family therapy, we work to foster family
    strengths and help families experience more enjoyment and connection. Together we work to build strong
    relationships, healthy communication, and effective problem solving. Services address power struggles within
    families and challenges with limit setting and control. We seek to help family members gain insight and try new ways
    of relating in the therapy context to support new experiences in day to day life. Therapy is geared to children’s
    developmental level and utilizes age appropriate activities, from play based interactions for younger children to
    collaborative problem solving for older children.</p>
<!-- END OF FAMILY THERAPY CONSULT COMPONENT -->