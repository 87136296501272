<!-- START OF ABOUT COMPONENT -->
<!-- page wrapper -->
<main class="site-inner">
    <!-- page header -->
    <header class="page-header">
        <h1 class="page-title">
            About Our Team
        </h1>
    </header>
    <!-- page content -->
    <div class="page-content">
        <!-- cheryl title -->
        <h4 class="name-title">
            Cheryl Holland, Ph.D.
        </h4>
        <!-- cheryl headshot -->
        <div class="caption headshot alignright">
            <img src="../../assets/images/about.webp" 
                alt="Cheryl Holland Ph.D. Headshot" loading="lazy"
                aria-describedby="headshot-caption">
            <p class="caption-text headshot-caption">
                Cheryl Holland, Ph.D.
            </p>
        </div>
        <!-- cheryl bio -->
        <p>I am a licensed psychologist with over 30 years of experience working with children, families, and adults.
            After earning a B.S. at Duke University in 1990, I attended the University of Maryland at College Park’s
            Clinical/Community Psychology graduate program. I earned an M.A. in 1992 and a Ph.D. in 1996, including
            internship training at Children’s National Medical Center in Washington, DC. My previous staff positions
            include psychologist at Kennedy Krieger Family Center through the Kennedy Krieger Institute in Baltimore, MD
            and the Reginald S. Lourie Center for Infants & Young Children in Rockville, MD. Since 2006, I primarily
            serve children and families from the Washington, DC area through my private practice in Kensington,
            Maryland.</p>
        <p>I am guided by the belief that individuals and families possess great inner strength that is fully expressed
            through nurturing relationships, empathic listening, and collaborative problem solving. I have extensive
            experience providing psychological evaluations, treatment, and consultation addressing a broad array of
            emotional, social, developmental, and behavioral concerns. My areas of specialization include early
            intervention with young children, including those with challenging temperament and behavior as well as
            difficulties with anxiety, mood, attention, self-control, and social skills. I also work extensively with
            children who have sensory-processing or language challenges that impact their emotional and behavioral
            development. Valuing interdisciplinary collaboration, I consult closely with providers in a variety of other
            disciplines and am privileged to work alongside independent professionals in my suite including occupational
            therapists, speech & language therapists, and special educators.</p>
        <p>My approach emphasizes interventions that build on an individual’s strengths and supports positive coping
            skills. Increasingly I am bringing mindfulness-based practices for various ages into my interventions to
            support awareness and distress tolerance. When working with children, I incorporate strong family
            involvement and guidance, as parents strive to support their child’s well-being and positive development. As
            needed, I provide school consultation to support children’s success and mastery in the classroom.</p>
        <p>Beyond the office setting, I enjoy conducting training seminars in the community with parents and
            professionals on topics of child development and healthy family relationships. I also provide clinical
            supervision to other professionals in the helping community. Through the Lourie Center for Children’s Social
            & Emotional Wellness in Rockville, Maryland, I have a long standing supervisory and training role in the
            areas of early childhood mental health and family support.</p>
        <p>Outside of my practice, I enjoy spending time on long walks in nature or on my yoga mat finding strength and
            balance. I am also a wife and the mother of three young adult children. They
            continue to teach me about the beautiful array of temperaments, my ongoing capacity to grow, and the power
            of openness, listening, and love.</p>
        <p>
            Contact Cheryl: <a href="mailto:drcheryl@cherylhollandphd.com">
                <span>drcheryl@cherylhollandphd.com</span>
            </a>
        </p>
        <br>
        <!-- gwen title -->
        <h4 class="name-title">
            Gwen Campbell, LCPC
        </h4>
        <!-- gwen headshot -->
        <div class="caption headshot alignright">
            <img src="../../assets/images/gwen-headshot.webp" 
                alt="Gwen Campbell LCPC Headshot" loading="lazy"
                aria-describedby="headshot-caption">
            <p class="caption-text headshot-caption">
                Gwen Campbell, LCPC
            </p>
        </div>
        <!-- gwen bio -->
        <p>I am a licensed clinical professional counselor with 17 years experience working with children and families.
            After earning a B.A. in Psychology at Albright College in Reading, PA in 2002, I attended the Pennsylvania
            State University in State College, PA and completed my graduate program in Counselor Education. I earned my
            M.Ed. in 2004. My training and professional experiences have included work in a variety of therapeutic
            settings with teens, children, and families. Since 2007, I have held a staff position at the Lourie Center
            for Children’s Social and Emotional Wellness in Rockville, MD and at Montgomery County Public Schools in
            Rockville, MD supporting the Montgomery County Infants and Toddlers Program.</p>
        <p>I am a flexible and out of the box thinker who prioritizes listening and positive communication as an
            essential part of developing collaborative relationships with children and families.
            My professional expertise includes social emotional evaluations, ongoing individual and family
            strengths-based coaching treatment, routines based strategies, child-centered play therapy, and observations
            in community settings such as preschools to provide consultation and support. My areas of specialization
            include early intervention with children birth to elementary age addressing social communication and
            engagement, sensory regulation and processing, sleep and eating routines, atypical attention, social skill
            and play skill development, and negative behaviors. Throughout my professional career, I have worked
            actively as a part of interdisciplinary teams and conducted childcare and school consultation, including
            guidance around individualized school support plans. I also conduct trainings in the area of social
            emotional development and assessment for local agencies and clinical supervision to future Licensed Clinical
            Professional Counselors.</p>
        <p>My professional qualifications also include credentials as a Certified Infant Massage Instructor and a
            certified Project ImPACT Therapist. Project ImPACT is well recognized as a highly effective, comprehensive
            coaching program for parents of young children with Autism Spectrum Disorder and related
            social-communication challenges. <a href="https://www.project-impact.org/">www.project-impact.org</a></p>
        <p>
            I am a wife and a mother of two passionate and active elementary and middle school children who remind me of
            the importance of what I do every day. They also remind me of the need to have courage, make mistakes, take
            breaks, know yourself, and to always have a pair of sneakers in the car for an unexpected adventure.</p>
        <p>
            Contact Gwen: <a href="mailto:gwen@cherylhollandphd.com ">
                <span>gwen@cherylhollandphd.com</span>
            </a>
        </p>
        <br>
        <!-- kelly title -->
        <h4 class="name-title">
            Kelly Rush, LCSW-C
        </h4>
        <!-- kelly headshot -->
        <div class="caption headshot alignright">
            <img src="../../assets/images/kelly-headshot.webp" 
                alt="Kelly Rush LCSW-C Headshot" loading="lazy"
                aria-describedby="headshot-caption">
            <p class="caption-text headshot-caption">
                Kelly Rush, LCSW-C
            </p>
        </div>
        <!-- kelly bio -->
        <p>I am a Licensed Certified Social Worker-Clinical (LCSW-C) and have spent my career supporting parents, 
            children and families in a variety of settings.  Over the years, I have developed a strong interest in 
            working with parents, as I understand how impactful it can be to the entire family when parents are supported 
            and strengthened.   I strive to help parents strengthen their relationship with their child(ren), and find 
            happiness and joy in both themselves and their family.  I come to this work with a foundation in empathy 
            and understanding, and strive to listen, support and help families grow.</p>
        <p>In 2006 I obtained a Master’s of Science in Social Work from Columbia University, concentrating in Clinical Social 
            Work.  Prior to that, I earned a Bachelor’s of Arts in both Sociology and Communication from Muhlenberg College 
            in 2004.</p>
        <p>Prior to joining the practice, I worked for several years as a Clinical Supervisor to Mental Health Clinicians 
            with the Lourie Center for Children’s Social & Emotional Wellness, in the Montgomery County Infants and Toddlers 
            Program (MCITP). Prior to supervision, I worked for eight years in MCITP as a Child Development Specialist, 
            focusing on parent-child attachment and social-emotional development with young children and families.  I regularly 
            completed developmental assessments and provided ongoing Family Counseling to support the social-emotional needs 
            of young children.  Family Counseling was completed via a Parent Coaching method, which really fostered my love 
            of working directly with parents.  I am passionate about empowering parents through a strength-based perspective 
            that is focused on healthy attachment with their child(ren).</p>
        <p>Throughout my career I have enjoyed giving professional development trainings to clinical staff on issues such as 
            social-emotional development in young children, working with the child welfare population, professional boundaries, 
            and self-care.  I have also presented workshops to foster parents around the social and emotional development of 
            infants and toddlers, especially as it relates to attachment and trauma.  From 2016-2018, I also served as a Clinical 
            Advisor and Professional Development Trainer for Momseze, a tele-health company that provided lactation and parenting 
            support to new parents.  Additionally, I am a trained facilitator of The Circle of Security Parenting Program, and 
            have been trained in the Social and Emotional Foundations of Early Learning (SEFEL) model.</p>
        <p>I am also a wife, and mom to two energetic and entertaining children, as well as a sweet dog, who all keep me on my 
            toes!  They bring empathy and compassion into all the work that I do. Outside of work I enjoy volunteering at my 
            children’s school, where among other things, I recently started and co-facilitate a Neurodiversity Affinity Group 
            for families.  </p>
        <p>
            Contact Kelly: <a href="mailto:kelly@cherylhollandphd.com">
                <span>kelly@cherylhollandphd.com</span>
            </a>
        </p>
    </div>
    <br>
</main>
<!-- END OF ABOUT COMPONENT -->