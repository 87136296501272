<!-- START OF RESOURCES COMPONENT -->
<!-- page wrapper -->
<main class="site-inner">
    <!-- page header -->
    <header class="page-header">
        <h1 class="page-title" itemprop="headline">
            Resources
        </h1>
    </header>
    <!-- page content -->
    <div class="page-content" itemprop="text">
        <!-- accordion menu -->
        <div class="resource-nav two-thirds first">
            <!-- accordion entry -->
            <div *ngFor="let item of resourceData; let n = index;">
                <!-- toggle button -->
                <button class="toggle-accordion" (click)="toggleEntry($event, n)" 
                    [innerHTML]="item.sectionName"></button>
                <!-- entry content -->
                <div class="accordion-panel" hide="!item.isActive">
                    <ul class="bullet-list">
                        <!-- entry bullet point -->
                        <li class="bullet" *ngFor="let bullet of item.standard" 
                            [innerHTML]="bullet"></li>
                        <li class="bullet" *ngIf="item.children.length > 0">Children/Parents:
                            <ul class="sublist">
                            <li class="bullet" *ngFor="let bullet of item.children" 
                                [innerHTML]="bullet"></li>
                            </ul>
                        </li>
                        <li class="bullet" *ngIf="item.adults.length > 0">Adults:
                            <ul class="sublist">  
                            <li class="bullet" *ngFor="let bullet of item.adults" 
                                [innerHTML]="bullet"></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- rightaligned images -->
        <div class="resource-img one-third">
            <img src="../../assets/images/pencils.webp" 
                loading="lazy" alt="cup of pencils">
            <img src="../../assets/images/books.webp" 
                loading="lazy" alt="row of books">
        </div>
    </div>
    <br>
</main>
<!-- END OF RESOURCES COMPONENT -->