<!-- START OF HOME COMPONENT -->
<!-- page wrapper -->
<div class="front-page">
    <!-- header carousel -->
    <mdb-carousel [isControls]="false" class="carousel slide carousel-fade" [animation]="'fade'">
        <!-- carousel images -->
        <mdb-carousel-item>
            <img src="../../assets/images/header-hands.webp" rel="preload" as="image" 
                class="carousel-image desktop-slide w-100" alt="first slide">
            <img src="../../assets/images/header-hands-tablet.webp" rel="preload" as="image" 
                class="carousel-image tablet-slide w-100" alt="first slide">
            <img src="../../assets/images/header-hands-mobile.webp" rel="preload" as="image" 
                class="carousel-image mobile-slide w-100" alt="first slide">
        </mdb-carousel-item>
        <mdb-carousel-item>
            <img src="../../assets/images/header-shoulders.webp" rel="preload" as="image" 
                class="carousel-image desktop-slide w-100" alt="second slide">
            <img src="../../assets/images/header-hands-tablet.webp" rel="preload" as="image" 
                class="carousel-image tablet-slide w-100" alt="first slide">
            <img src="../../assets/images/header-shoulders-mobile.webp" rel="preload" as="image" 
                class="carousel-image mobile-slide w-100" alt="second slide">
        </mdb-carousel-item>
        <mdb-carousel-item>
            <img src="../../assets/images/header-bubbles.webp" rel="preload" as="image" 
                class="carousel-image desktop-slide w-100" alt="third slide">
            <img src="../../assets/images/header-hands-tablet.webp" rel="preload" as="image" 
                class="carousel-image tablet-slide w-100" alt="first slide">
            <img src="../../assets/images/header-bubbles-mobile.webp" rel="preload" as="image" 
                class="carousel-image mobile-slide w-100" alt="third slide">
        </mdb-carousel-item>
        <!-- carousel header -->
        <div class="carousel-caption">
            <!-- header background -->
            <div class="caption-bar"></div>
            <!-- header content -->
            <div class="caption-text">
                <br><br>
                <!-- header text -->
                <h1 class="carousel-title" id="title-text">
                    Welcome to Cheryl Holland, Ph.D. & Associates, LLC<br>
                    Licensed Mental Health Professionals</h1>
                <h2 class="carousel-subtitle-primary">
                    Supporting Growth & Connection<br>
                    Building Strength to Navigate Life’s Challenges</h2>
                <h3 class="carousel-subtitle-secondary">
                    Serving Children, Families, & Adults<br>
                    Office & Telehealth Sessions</h3><br>
                <!-- navigation buttons -->
                <div class="row button-row">
                    <!-- scroll down button -->
                    <h4 class="carousel-btn col-6">
                        <a class="button carousel-link" (click)="scroll(intro)">
                            Meet<br>Dr. Holland<br><br>
                            <i class="fa fa-arrow-down"></i>
                        </a>
                    </h4>
                    <!-- contact page link -->
                    <h4 class="carousel-btn col-6">
                        <a class="button carousel-link" routerLink="/contact" routerLinkActive="active">
                            Contact<br>Us<br><br>
                            <i class="fa fa-envelope"></i>
                        </a>
                    </h4>
                </div>
                <br>
            </div>
        </div>
    </mdb-carousel>
    <!-- page content -->
    <div class="site-inner" #intro>
        <!-- intro blurb -->
        <div class="intro">
            <!-- headshot -->
            <div class="headshot one-third first" id="desktop-shot">
                <img src="../../assets/images/welcome.webp" alt="cheryl headshot">
            </div>
            <!-- intro text -->
            <div class="blurb two-thirds">
                <h2><em>Welcome!</em></h2>
                <p>
                    My name is Cheryl Holland. I am a licensed psychologist committed to helping children, adults,
                    and families to grow, connect, and experience more joy in their lives. For over 30 years, I have
                    had the privilege of offering support, treatment, and consultation to clients from the
                    Washington, DC area. I aim to identify strengths, to support insight and skill building, and to
                    help others navigate life challenges. My services are flexibly tailored to an individual’s and
                    family’s needs, ranging from evaluation and short term support to comprehensive intervention. I
                    strive to help others develop positive well-being, cultivate healthy, nurturing relationships,
                    and experience positive change. While I support others as they make active changes in their
                    lives, I also help individuals and families experience healthier tolerance of distress in
                    adaptive ways.
                </p>
                <!-- headshot -->
                <p>
                    I am pleased to have two associates as part of my team, Gwen Campbell, LCPC & Kelly Rush,
                    LCSW-C. They are both licensed professionals, bringing a wealth of experience and warm
                    commitment to the children and families they serve. We welcome individuals of all cultures, 
                    races, ethnicities, sexual orientations, gender identities, and abilities. Our clinicians 
                    are committed to providing an inclusive space for all people and offering support for all 
                    families. Learn more about us on the “About” page.
                </p>
                <!-- scroll button -->
                <a class="button" (click)="scroll(services)" mdbWavesEffect>
                    Learn About Our Service Offerings<br>
                    <i class="fa fa-arrow-down"></i>
                </a>
            </div>
        </div>
        <!-- services widgets -->
        <div class="service-grid" #services>
            <div class="row">
                <!-- evaluation card -->
                <div class="service-card col-4">
                    <a routerLink="/services/evaluation" routerLinkActive="active">
                        <img src="../../assets/images/img-evaluation.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="mother hugging daughter in field"/>
                        <h2 class="service-header">Evaluation</h2>
                    </a>
                </div>
                <!-- individual therapy card -->
                <div class="service-card col-4">
                    <a routerLink="/services/individual-therapy" routerLinkActive="active">
                        <img src="../../assets/images/img-individual-therapy.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="girl pouting on bench"/>
                        <h2 class="service-header">Individual Therapy</h2>
                    </a>
                </div>
                <!-- family therapy card -->
                <div class="service-card col-4">
                    <a routerLink="/services/family-therapy" routerLinkActive="active">
                        <img src="../../assets/images/img-family-therapy.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="mother and son in field"/>
                        <h2 class="service-header">Family Therapy</h2>
                    </a>
                </div>
            </div>
            <div class="row">
                <!-- parent consultation card -->
                <div class="service-card col-4">
                    <a routerLink="/services/parent-consultation" routerLinkActive="active">
                        <img src="../../assets/images/img-parent-consult.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="dad hugging smiling daughter"/>
                        <h2 class="service-header">Parent Consultation</h2>
                    </a>
                </div>
                <!-- school consultation card -->
                <div class="service-card col-4">
                    <a routerLink="/services/school-consultation" routerLinkActive="active">
                        <img src="../../assets/images/img-school-consult.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="children running in field"/>
                        <h2 class="service-header">School Consultation</h2>
                    </a>
                </div>
                <!-- supervision & training card -->
                <div class="service-card col-4">
                    <a routerLink="/services/supervision-and-training" routerLinkActive="active">
                        <img src="../../assets/images/img-supervision.webp" 
                            class="service-img" loading="lazy"
                            itemprop="image" alt="kid with chalk drawings"/>
                        <h2 class="service-header">Supervision &amp; Training</h2>
                    </a>
                </div>
            </div>
        </div>
        <br>
    </div>
    <!-- covid-19 information modal -->
    <div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-notify modal-info" role="document">
            <!-- modal content -->
            <div class="modal-content">
                <div class="modal-header text-center">
                    <!-- modal title -->
                    <h4 class="modal-title white-text w-100 font-weight-bold py-2">
                        Covid-19 Information
                    </h4>
                    <!-- modal close button -->
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                        <span class="white-text">&times;</span>
                    </button>
                </div>
                <!-- modal text -->
                <div class="modal-body">
                    <!-- update date -->
                    <i>Updated: 07/01/21</i><br><br>
                    Due to the COVID-19 pandemic, this practice transitioned to providing all services via Telehealth in
                    March 2020. As of July 1, 2021, in some situations, we will begin to offer in-person services where
                    this type of service may be appropriate. Telehealth will continue to be offered. All clinicians at
                    the practice have been fully vaccinated for COVID-19. We are fully committed to following state and
                    federal guidelines and adhering to prevailing professional healthcare standards to limit the
                    transmission of COVID-19 at our practice. Numerous safety precautions are in place to protect
                    everyone’s health and safety. Please check with your clinician for more details.
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END OF HOME COMPONENT -->