import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-therapy',
  templateUrl: './family-therapy.component.html',
  styleUrls: [
    './family-therapy.component.scss',
    '../services.component.scss'
  ]
})
export class FamilyTherapyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
