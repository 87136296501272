
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms'; 
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';

import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ResourcesComponent } from './resources/resources.component';
import { ServicesComponent } from './services/services.component';

import { OverviewComponent } from './services/overview/overview.component';
import { EvaluationComponent } from './services/evaluation/evaluation.component';
import { IndividualTherapyComponent } from './services/individual-therapy/individual-therapy.component';
import { FamilyTherapyComponent } from './services/family-therapy/family-therapy.component';
import { ParentConsultComponent } from './services/parent-consult/parent-consult.component';
import { SchoolConsultComponent } from './services/school-consult/school-consult.component';
import { SupervisionComponent } from './services/supervision/supervision.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ClientsComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ResourcesComponent,
    ServicesComponent,
    EvaluationComponent,
    IndividualTherapyComponent,
    FamilyTherapyComponent,
    ParentConsultComponent,
    SchoolConsultComponent,
    SupervisionComponent,
    OverviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    NgbModule
  ],
  providers: [ ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
