import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ResourcesComponent } from './resources/resources.component';

import { ServicesComponent } from './services/services.component';
import { EvaluationComponent } from './services/evaluation/evaluation.component';
import { IndividualTherapyComponent } from './services/individual-therapy/individual-therapy.component';
import { FamilyTherapyComponent } from './services/family-therapy/family-therapy.component';
import { ParentConsultComponent } from './services/parent-consult/parent-consult.component';
import { SchoolConsultComponent } from './services/school-consult/school-consult.component';
import { SupervisionComponent } from './services/supervision/supervision.component';
import { OverviewComponent } from './services/overview/overview.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'who-we-serve', component: ClientsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'home', component: HomeComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'services', component: ServicesComponent, children: [
    { path: '', component: OverviewComponent },
    { path: 'evaluation', component: EvaluationComponent },
    { path: 'individual-therapy', component: IndividualTherapyComponent },
    { path: 'family-therapy', component: FamilyTherapyComponent },
    { path: 'parent-consultation', component: ParentConsultComponent },
    { path: 'school-consultation', component: SchoolConsultComponent },
    { path: 'supervision-and-training', component: SupervisionComponent }
  ]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
