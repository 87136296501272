import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent-consult',
  templateUrl: './parent-consult.component.html',
  styleUrls: [
    './parent-consult.component.scss',
    '../services.component.scss'
  ]
})
export class ParentConsultComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
