<!-- START OF SUPERVISION COMPONENT -->
<!-- section header -->
<header class="page-header">
        <h1 class="page-title" itemprop="headline">
                Supervision &amp; Training
        </h1>
</header>
<!-- section image -->
<img src="../../assets/images/img-supervision.webp"
    class="service-img" loading="lazy"
    alt="kid with chalk drawings">
<!-- section content -->
<p>Dr. Holland conducts trainings and seminars in the community on issues related to children’s 
        social-emotional and behavioral health. Selected training titles include:</p>
<ul>
    <li>I Can Be the Boss of Me: Supporting Self-Control Skills in Young Children</li>
    <li>We’re All Wired Differently: Working with Temperament in the Early Childhood Classroom</li>
    <li>Children with Special Needs in the Preschool Classroom: Understanding and Accommodating 
            Significant Social-Emotional, Behavioral, &amp; Sensory Challenges</li>
    <li>Anxious Children in the Preschool Classroom:&nbsp;Connecting, Calming, &amp; Growing</li>
</ul>
<p>Dr. Holland also provides supervision and consultation to other mental health professionals in their 
        clinical work. Arrangements can be made to flexibly meet the needs of the supervisee in 
        terms of frequency and content.</p>
<!-- END OF SUPERVISION COMPONENT -->